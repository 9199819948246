.datasetsDatatableContainer {
    flex: 18;
    background-color: #FFF;
    width: 1000px;

    .header {
        padding: 30px;

        h3 {
            color: #004D22;
        }
    }

    .MuiDataGrid-columnHeaders {
        background-color: #F5F5F6;
    }

    .MuiDataGrid-iconSeparator {
        display: none;
    }

    .sjalala {
        display: flex;
        align-items: center;
        justify-content: center;

        .beforehover {
            background-color: #F3FAF6;
            opacity: 0.7;
            width: 120px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            position: fixed;

            &:hover {
                opacity: 0;
            }
        }

        .afterhover {
            background-color: #F3FAF6;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            position: fixed;

        }
    }
}