.communicationtasksnavbar{
    display: flex;
    align-items: center;
    height: 55px;

    .titleCounter{
        display: flex;
        align-items: center;
        width: 280px;
        padding: 15px 10px;
        color: #004D22;

        span{
            padding-left: 5px;
            font-weight: bold;
        }
    }

    .fromDatePicker,
    .untilDatePicker{
        width: 180px;
    }
}