.subsidebar{
    flex: 3;
    border-right: 0.5px solid #34845b48;

    .menuList{
        list-style: none;
        padding: 0;

        .listItem{
            display: flex;
            //background-color: coral;
            padding: 10px 5px 10px 25px;
            border-bottom: 0.5px solid #E1E2E1;
            cursor: pointer;

            &:hover{
                background-color: #8dedbb21;
            }
            
            &.datasets.selected{
                border-left: 3px solid #3F536E;
                background-color: #8dedbb21;
            }
            
            &.languages.selected{
                border-left: 3px solid #AB7DF6;
                background-color: #8dedbb21;
            }
            
            &.integrations.selected{
                border-left: 3px solid #E0102B;
                background-color: #8dedbb21;
            }

            &.usergroups.selected{
                border-left: 3px solid #40B630;
                background-color: #8dedbb21;
            }

            .iconBox{
                padding: 5px;
                color: #FFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.datasets{
                    background-color: #3F536E;
                }

                &.languages{
                    background-color: #AB7DF6;
                }

                &.integrations{
                    background-color: #E0102B;
                }

                &.usergroups{
                    background-color: #40B630;
                }

                &.configuration{
                    background-color: rgb(240, 127, 14);
                }
            }

            .settingsTitle{
                //background-color: lightgreen;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 10px;

                    .main{
                        font-weight: 500;
                        margin-bottom: 5px;
                    }

                    .sub{
                        font-size: 12px;
                        font-weight: 300;
                    }
            }
        }
    }
}