.conversations {
    display: flex;
    // min-height: 100vh;
    max-height: 100vh;
    overflow-y: hidden;

    .conversationWrapper {
        flex: 21;
        //background-color: #F5F5F6;
        background-color: #FFF;
        display: flex;
        
        .conversationMenu {
            flex: 2;
            display: flex;
            flex-direction: column;
            
            .conversationMenuBody {
                flex: 1;
            }
            
            .conversationMenuFooter {
                height: 60px;
                background-color: #BDBDBD;
            }
        }
        
        .conversationContainer {
            flex: 9;
            display: flex;
            
            .conversationBox {
                flex: 15;
                background-color: #E1E2E1;
                border-left: 0.5px solid #919192;
                border-right: 0.5px solid #919192;
            }
            
            .conversationProfile {
                flex: 4;
            }
        }
    }
}