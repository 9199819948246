.integrationsmainpage{
    flex: 18;
    background-color: #47aa7721;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
        padding: 30px;
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title { 
            padding-left: 20px;
            h3 {
                color: #004D22;
            }
        }

        .newButton{
            
        }
    }
}