.commentsContainer{
    width: 1050px;

    .headerTitle {
    }

    .commentsList{
        display: flex;
        flex-direction: column;
        padding: 5px;
        gap: 5px;
        
        .commentsListHeader {
            font-weight: bold;
        }

        .commentsListHeader,
        .commentsListItem{
            display: flex;
            padding: 2px;
            border-bottom: 0.5px lightgray solid;
            
            .commentId{
                width: 20px;
            }

            .commentCreatedAtDate{
                display: flex;
                align-items: center;
                width: 90px;
            }

            .commentCreatedAtTime{
                display: flex;
                align-items: center;
                width: 60px;
            }

            
            .commentMessageContent{
                display: flex;
                align-items: center;
                width: 500px;
            }
            
            .commentUserFullName{
                display: flex;
                align-items: center;
                width: 130px;
            }
            
            
            .commentStartStatus,
            .commentEndStatus {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;

                span{
                    padding: 2px 5px;
                    border-radius: 4px;

                    &.start{
                        background-color: rgba(255, 160, 122, 0.585);
                    }

                    &.open{
                        background-color: rgba(255, 0, 0, 0.618);
                    }

                    &.inprogress{
                        background-color: rgba(255, 166, 0, 0.445);
                    }

                    &.closed{
                        background-color: rgba(0, 128, 0, 0.494);
                    }
                }
            }

        }
    }

    .addCommentContainer{
        margin-top: 15px;
        padding: 2px;

        .addCommentForm{
            padding: 2px;

            .fieldContainer{
                display: flex;
                gap: 20px;

                .messageContent{
                    flex: 1;
                    background-color: #FFF;
                }

                .updatedStatus{
                    width: 150px;

                }
            }

            .submitBtn {
                margin-top: 5px;
            }
        }
    }
}