.conversationListItem{
    background-color: #FFF;
    display: flex;
    border-bottom: 0.5px solid #E1E2E1;
    padding: 15px;
    cursor: pointer;
    
    &.selected{
        background-color: #F5F5F6;
    }

    .userData{
        margin-left: 10px;

        .userName{
            display: flex;
            gap: 10px;
        }
    }
}