.singleDatasetWrapper {
    flex: 18;
    background-color: #47aa7721;
    display: flex;
    justify-content: center;

    .singleDatasetContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .header {
            padding: 30px;
            width: 1000px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                color: #004D22;
            }
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            width: 1000px;
            background-color: #FFF;
            -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);
            box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);

            .tabMenu {
                background-color: #E0EFE9;


                .singleTab {}
            }


            .dataresultsContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
        }
    }
}