.conversationReplyBox {
    height: 60px;
    display: flex;
    
    .inputField{
        padding: 10px;
        width: 400px;
        flex: 1;
    }
    
    .sendButton{
        padding: 10px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}