.communicationtasksdatatable{
    flex: 1;
    width: 100%;

    .infoproduct__description{
        color: #004D22;
    }
    
    .infoproduct__user,
    .infoproduct__messagecontent{
        color: #004D22;
        font-size: 12px;
    }

    // .sjalala {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     .beforehover {
    //         background-color: #F3FAF6;
    //         opacity: 0.7;
    //         width: 120px;
    //         height: 50px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         z-index: 2;
    //         position: fixed;

    //         &:hover {
    //             opacity: 0;
    //         }
    //     }

    //     .afterhover {
    //         background-color: #F3FAF6;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         z-index: 1;
    //         position: fixed;
    //     }
    // }
}