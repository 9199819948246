.singleCompanyForm {
    flex: 18;
    background-color: #47aa7721;
    display: flex;
    justify-content: center;

    .singleCompanyFormContainer {
        flex: 1;
        max-width: 800px;
        display: flex;
        flex-direction: column;

        .header {
            padding: 30px;

            h3 {
                color: #004D22;
            }
        }

        .content {
            flex: 1;
            background-color: #FFFFFF;
            -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);
            box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);

            .field-input {
                display: flex;
                padding: 5px 30px 0px 30px;
                gap: 10px;

                .field-input__header {
                    flex: 2;
                    padding: 3px 10px;
                    display: flex;
                    align-items: center;

                    label {
                        font-weight: bold;
                        font-size: 13px;
                        color: #004D22;
                    }
                }

                .field-input__wrapper {
                    flex: 7;
                    padding: 3px 10px;

                    .field-input__container {

                        .field-input__input {
                            width: 100%;

                            input {
                                height: 15px;
                            }
                        }
                    }
                }
            }

            .submitBtn{
                display: flex;
                justify-content: flex-end;
                padding: 30px 40px;
            }
        }
    }
}