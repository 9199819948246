.conversationMenuBody{
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar{
        width: 5px;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #004d216b;
    }


}