.datatable {
    flex: 1;
    width: 100%;

    .MuiDataGrid-columnHeaders {
        background-color: #F5F5F6;
    }

    .MuiDataGrid-iconSeparator {
        display: none;
    }

    .fullName,
    .company__city,
    .company__name {
        font-weight: bold;
        font-size: 14px;
    }

    .user__phone {
        display: flex;
        align-items: center;

        span {
            padding-left: 5px;
            font-weight: bold;
        }
    }

    .company__location {

        .company__country {
            display: flex;
            gap: 5px;

            span {
                color: #004D22;
                font-size: 13px;
                font-weight: 400;
            }
        }
    }

    .user__active,
    .user__notactive {
        color: #FFF;
        padding: 2px 5px;
        border-radius: 3px;
    }

    .user__active {
        background-color: #007A4B;
    }

    .user__notactive {
        background-color: rgb(150, 6, 6);
    }

    .user__type,
    .company__role {
        color: #004D22
    }

    .sjalala {
        display: flex;
        align-items: center;
        justify-content: center;

        .beforehover {
            background-color: #F3FAF6;
            opacity: 0.7;
            width: 120px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            position: fixed;

            &:hover {
                opacity: 0;
            }
        }

        .afterhover {
            background-color: #F3FAF6;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            position: fixed;
        }
    }

    .cellContainer {
        background-color: red;
        padding: 5px;

        .rowNotActive {
            background-color: lightgray;
            width: 100%;
            height: 50px;
        }

        .rowActive {
            position: fixed;
        }
    }
}