.login{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .loginContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.5px solid lightgrey;
        border-radius: 5px;
        padding: 20px;

        .login-field{
            margin-bottom: 10px;
            width: 300px;
        }

        .login-btn{
            margin-top: 10px;
            width: 200px;
        }

        p{
            margin-top: 10px;
            font-size: 12px;
            font-weight: bold;
            color: red;
        }
    }
}