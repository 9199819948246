.list{
    display: flex;
    min-height: 100vh;

    .listContainer{
        flex: 18;
        //background-color: #F5F5F6;
        background-color: #FFF;
        display: flex;
        flex-direction: column;

        .listContainerContent{
            flex: 1;
            display: flex;
        }

    }
}