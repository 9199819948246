.receptionControlMainPage{
    display: flex;

    .receptionControlMainPageContainer{
        flex: 21;
        display: flex;
        flex-direction: column;

        .filterContainer{
            // background-color: coral;
            display: flex;

            .dateFilterContainer{
                flex: 1;
                // background-color: aqua;
                display: flex;
                flex-direction: column;
                
                .fromDateContainer,
                .untilDateContainer{
                    display: flex;
                    flex-direction: column;
                    padding: 10px;
                    gap: 5px;

                    .labelContainer{
                        label{
                            font-size: 14px;
                        }
                    }
                }
            }
            
            .relationFilterContainer{
                flex: 1;
                // background-color: lightgreen;
                
                .growerFilterContainer{
                    
                }
                
                .buyerFilterContainer {
                    
                }
            }
            
            .statusFilterContainer{
                flex: 2;
                // background-color: lightpink;
                display: flex;
            }

            .actionButtonsContainer{
                flex: 1;
                // background-color: yellow;
            }
        }
    }
}