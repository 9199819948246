.receptionControlSingleBatch{
    display: flex;

    .singleBatchContainer{
        flex: 18;
        background-color: #47aa7721;
        display: flex;
        flex-direction: column;
        align-items: center;

        .caseStatus{
            display: flex;
            justify-self: flex-end;
            align-self: flex-end;

            span {
                font-size: 20px;
                padding: 2px 5px;
                border-radius: 4px;

                &.start{
                    background-color: rgba(255, 160, 122, 0.585);
                }

                &.open{
                    background-color: rgba(255, 0, 0, 0.618);
                }

                &.inprogress{
                    background-color: rgba(255, 166, 0, 0.445);
                }

                &.closed{
                    background-color: rgba(0, 128, 0, 0.494);
                }
            }
        }

        .singleBatchData{
            margin-top: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            // background-color: aqua;
            align-items: center;

            .header,
            .batchIdData,
            .supplyLetterData,
            .supplierData,
            .growerData,
            .buyerData,
            .productData,
            .packageData,
            .quantityData,
            .priceData,
            .trolleyData,
            .trolleyQtyData,
            .shelveQtyData {
                flex: 1;
                display: flex;
                
                .labelItem{
                    width: 150px;
                    border: 1px solid gray;
                }
                
                .orderData{
                    width: 300px;
                    border: 1px solid gray;
                }
                
                .eabData{
                    width: 300px;
                    border: 1px solid gray;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .titleContainer{
                        padding: 5px;
                        display: flex;
                        justify-content: space-between;

                        .statusCirlce{
                            background: red;
                            width: 20px;
                            height: 20px;
                            border-radius: 20%;
                            
                            &.greenAndRed{
                                background: linear-gradient( 90deg, green, green 50%, red 50% );
                            }

                            &.green{
                                background: green;
                            }
                        }
                        }

                    &.red{
                        background-color: rgba(255, 0, 0, 0.683);
                    }
                    
                }
                
                .receivementData{
                    width: 300px;
                    border: 1px solid gray;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .titleContainer{
                        padding: 5px;
                        display: flex;
                        justify-content: space-between;

                        .statusCirlce{
                            background: red;
                            width: 20px;
                            height: 20px;
                            border-radius: 20%;
                            
                            &.greenAndRed{
                                background: linear-gradient( 90deg, green, green 50%, red 50% );
                            }

                            &.green{
                                background: green;
                            }
                        }
                        }
                    
                    &.red{
                        background-color: rgba(255, 0, 0, 0.683);
                    }
                }
            }

            .header{
                font-weight: bold;
            }
        }

        // .singleBatchComments {
        //     background-color: aqua;
        //     width: 1050px;

        // }
    }
}