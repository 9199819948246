.singleDataSetConfig{

    .singleDatasetConfigContainer{

        .stepper{
            padding: 20px 40px;
        }

        .stepContentContainer {
            flex: 1;

            .stepContentForm {

                .stepContentFormFields {

                    .field-input {
                        display: flex;
                        padding: 5px 30px 0 30px;
                        gap: 10px;

                        .field-input__header {
                            flex: 1;
                            padding: 3px 10px;
                            display: flex;
                            align-items: center;

                            label {
                                font-weight: bold;
                                font-size: 13px;
                                color: #004D22;
                            }
                        }

                        .field-input__wrapper {
                            flex: 7;
                            padding: 3px 10px;

                            .field-input__container {

                                .field-input__input {
                                    width: 100%;

                                    input {
                                        height: 15px;
                                    }
                                }

                                .refresh-schedule {
                                    //background-color: red;
                                    padding: 5px 0;
                                    display: flex;
                                    gap: 10px;
                                    padding-right: 10px;

                                    .weekdays {
                                        flex: 1;
                                        //background-color: cyan;

                                        .weekdaysToggleButtonGroup {
                                            margin-top: 5px;
                                            display: flex;
                                            gap: 1px;

                                            .weekdayToggleButton {
                                                background-color: #FFF;
                                                color: #004D22;

                                                &.Mui-selected {
                                                    background-color: #004D22;
                                                    color: #FFF;
                                                }
                                            }
                                        }
                                    }

                                    .frequency {
                                        flex: 1;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;

                                        .container {
                                            margin-top: 5px;
                                            display: flex;
                                            gap: 20px;

                                            .quantity {
                                                flex: 1;
                                            }

                                            .unit {
                                                flex: 2;
                                            }
                                        }
                                    }

                                    .timeslot {
                                        flex: 1;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;

                                        .container {
                                            margin-top: 5px;
                                            display: flex;
                                            gap: 10px;
                                            align-items: center;

                                            .fromTime,
                                            .untilTime {
                                                display: flex;
                                                gap: 2px;
                                                align-items: center;
                                            }
                                        }
                                    }

                                    .copyIcon {
                                        color: #004D22;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin-left: 30px;
                                    }
                                }
                            }
                        }
                    }

                    .columnMapping{
                        display: flex;
                        padding: 10px;

                        .fetchResults{
                            flex: 1;

                            .fetchResultList{
                                list-style: none;

                                .fetchResultListItem{
                                    margin: 10px 10px 10px 0;

                                    .fetchResultListItemContainer{
                                        background-color: #47aa7721;
                                        padding: 10px;
                                        display: flex;
                                        gap: 20px;
                                        border-radius: 5px;

                                        .fieldName{
                                            flex: 3;
                                            label{
                                                font-size: 12px;
                                                font-weight: bold;
                                            }

                                            p {
                                                font-size: 14px;
                                            }
                                        }

                                        .exampleValue{
                                            flex: 2;

                                            label{
                                                font-size: 12px;
                                                font-weight: bold;
                                            }

                                            p {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                        .tableCreator {
                            flex: 1;

                            .tableCreatorList {
                                list-style: none;

                                .tableCreatorListItem {
                                    margin: 10px;

                                    .tableCreatorListItemContainer {
                                        background-color: #47aa7765;
                                        padding: 10px;
                                        display: flex;
                                        gap: 20px;
                                        border-radius: 5px;

                                        .fieldName{
                                            flex: 4;
                                            label{
                                                font-size: 12px;
                                                font-weight: bold;
                                            }

                                            p {
                                                font-size: 14px;
                                            }
                                        }

                                        .exampleValue{
                                            flex: 3;

                                            label{
                                                font-size: 12px;
                                                font-weight: bold;
                                            }

                                            p {
                                                font-size: 14px;
                                            }
                                        }

                                        .fieldType{
                                            flex: 2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .stepContentFormButtons {
                    display: flex;
                    justify-content: flex-end;
                    gap: 20px;
                    padding: 30px 40px;

                    .submitBtn,
                    .cancelBtn {
                        display: flex;
                        justify-content: flex-end;
                        //padding: 30px 40px;
                    }
                }
            }
        }
    }
}