.addInfoProductForm {
    flex: 18;
    background-color: #47aa7721;
    display: flex;
    justify-content: center;

    .addInfoProductFormContainer {
        flex: 1;
        max-width: 1000px;
        display: flex;
        flex-direction: column;

        .header {
            padding: 30px;

            h3 {
                color: #004D22;
            }
        }

        .content {
            flex: 1;
            background-color: #FFF;
            -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);
            box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);

            .stepper {
                padding: 20px 40px;
            }

            .stepContentContainer {
                flex: 1;

                .stepContentForm {

                    .stepContentFormFields {

                        .field-input {
                            display: flex;
                            padding: 5px 30px 0px 30px;
                            gap: 10px;

                            .field-input__header {
                                flex: 2;
                                padding: 3px 10px;
                                display: flex;
                                align-items: center;

                                label {
                                    font-weight: bold;
                                    font-size: 13px;
                                    color: #004D22;
                                }
                            }

                            .field-input__wrapper {
                                flex: 7;
                                padding: 3px 10px;

                                .field-input__container {

                                    .field-input__input {
                                        width: 100%;

                                        input {
                                            height: 15px;
                                        }
                                    }

                                    .conditionListItem {
                                        background-color: rebeccapurple;
                                    }

                                }
                            }
                        }
                    }

                    .conditionsContainer {
                        padding: 10px;
                        display: flex;
                        flex-direction: column;

                        label {
                            margin: 5px;
                        }

                        .newCondition {
                            background-color: #CCE4DB;
                            display: flex;
                            padding: 10px;
                            gap: 10px;
                            margin: 5px;

                            .fieldNameAndExampleValue {
                                display: flex;
                                flex-direction: column;
                                gap: 5px;

                                .fieldName {
                                    .label {
                                        font-size: 12px;
                                    }

                                    .newConditionMenuItem {
                                        display: flex;
                                    }
                                }

                                .exampleValue {
                                    .label {
                                        font-size: 12px;
                                    }
                                }
                            }

                            .operator {
                                .label {
                                    font-size: 12px;
                                }
                            }

                            .conditionValue {
                                .label {
                                    font-size: 12px;
                                }
                            }

                            .saveButton {
                                flex: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .selectedConditions {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            //gap: 10px;
                            margin-top: 20px;

                            .singleCondition {
                                flex: 1;
                                background-color: #93C11F;
                                padding: 10px;
                                display: flex;
                                gap: 10px;
                                margin: 5px;

                                .fieldName {
                                    width: 280px;

                                    .label {
                                        font-size: 12px;
                                    }

                                    .value {
                                        padding: 2px;
                                    }
                                }

                                .operator {
                                    width: 250px;

                                    .label {
                                        font-size: 12px;
                                    }

                                    .value {
                                        padding: 2px;
                                    }
                                }

                                .conditionValue {
                                    width: 250px;

                                    .label {
                                        font-size: 12px;
                                    }

                                    .value {
                                        padding: 2px;
                                    }
                                }

                                .deleteButton {
                                    flex: 1;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }

                            }
                        }
                    }

                    .informationProductContent {
                        display: flex;
                        padding: 10px;

                        .input {
                            flex: 7;
                            padding: 5px;
                            display: flex;
                            flex-direction: column;

                            .textEditorHeader {
                                height: 50px;
                                display: flex;
                                gap: 15px;
                                margin-top: 20px;
                            }

                            .textEditorContent {
                                flex: 1;
                                background-color: #EFEFEF;
                                padding: 5px;
                            }
                        }

                        .example {
                            flex: 3;
                            padding: 5px;

                            .smartphoneContainer {
                                padding: 5px;
                                display: flex;
                                justify-content: center;

                                .smartphoneFrame {
                                    background-color: black;
                                    padding: 20px;
                                    height: 500px;
                                    width: 300px;
                                    display: flex;
                                    flex-direction: column;
                                    border-radius: 25px;

                                    .smartphoneContent {
                                        flex: 1;
                                        background-color: #99BB94;
                                        padding: 5px;

                                        .smartphoneSingleMessage {
                                            min-width: 80px;
                                            width: auto;
                                            max-width: 240px;
                                            white-space: pre-wrap;
                                            word-wrap: break-word;
                                            display: flex;
                                            flex-direction: column;
                                            background-color: #FFFFFF;
                                            padding: 5px 10px;
                                            border-radius: 10px;

                                            .messageText {

                                                pre {
                                                    white-space: pre-wrap;
                                                    inline-size: 240px;
                                                }

                                            }

                                        }


                                    }
                                }
                            }

                        }
                    }

                    .stepContentFormButtons {
                        display: flex;
                        justify-content: flex-end;
                        gap: 20px;
                        padding: 30px 40px;

                        .submitBtn,
                        .cancelBtn {
                            display: flex;
                            justify-content: flex-end;
                            //padding: 30px 40px;
                        }
                    }
                }
            }




        }
    }
}