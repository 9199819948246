.sidebar {
    flex: 1;
    min-height: 100vh;
    background-color: #004D22;
    color: #47AA77;
    display: flex;
    flex-direction: column;

    .top {
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: center;
        align-items: center;
        font-weight: bold;

        div{
            background-color: #C7F457;
            color: #000;
            display: flex;
            flex-direction: column;
            padding: 10px;
            
            p{
                text-align: center;
            }
        }
    }

    .center {
        font-size: 20px;
        font-weight: 500;

        ul {
            list-style: none;
            margin: 0;
            padding: 10px;

            li {
                display: flex;
                align-items: center;

                .menu-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    width: 100%;
                    cursor: pointer;
                }

                .selected{
                    color: #FFFFFF;
                }



            }

            li:hover {
                background-color: #92ada250;
            }
        }
    }

    .bottom {
        flex: 1;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        ul{
            list-style: none;
            margin: 0;
            padding: 10px;

            li{
                display: flex;
                align-items: center;

                div{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    width: 100%;
                    cursor: pointer;
                }

                .menu-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    width: 100%;
                    cursor: pointer;
                }

                .selected{
                    color: #FFFFFF;
                }

                .dialog{
                    padding: 50px;
                }
            }

            li:hover {
                background-color: #92ada250;
            }
        }
    }
}