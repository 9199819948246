.users{
    display: flex;
    min-height: 100vh;

    .usersContainer{
        flex: 17;
        //background-color: #F5F5F6;
        background-color: #FFF;
        display: flex;
        flex-direction: column;

        .usersContainerContent{
            flex: 1;
            display: flex;
        }
    }
}