.receptionControlBatchesDataTable{
    flex: 1;
    height: 100%;

    .MuiDataGrid-columnHeaders {
        background-color: #F5F5F6;
    }

    .MuiDataGrid-iconSeparator {
        display: none;
    }

    .orderdate_daylabel{
        color: #004D22;
        font-size: 10px;
    }
    
    .orderdate_value{
        color: #004D22;
    }
    
    .product__description{
        color: #004D22;
    }
    

        
        .eabData{
            color: #004D22;
        }
        
        .receivedStatus{
            color: #004D22;

        }
    

    .sjalala {
        display: flex;
        align-items: center;
        justify-content: center;

        .beforehover {
            background-color: #F3FAF6;
            opacity: 0.7;
            width: 120px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            position: fixed;

            &:hover {
                opacity: 0;
            }
        }

        .afterhover {
            background-color: #F3FAF6;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            position: fixed;
        }
    }

}