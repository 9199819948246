.receivementStatusFilterContainer{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .labelContainer{
        label{
            font-size: 14px;
        }
    }

    .inputContainer{

    .resetBtn{
        cursor: pointer;
        background-color: lightgray;
        border-radius: 5px;
        padding: 2px 5px;
        margin-left: 10px;
        font-weight: bold;
    }
}
}