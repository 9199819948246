.subsidebar {
    flex: 3;

    .subsidebarContainer {

        min-height: 100vh;
        background-color: #007A4B;
        color: #b2cfbf;
        display: flex;
        flex-direction: column;
        font-size: 14px;

        .top {
            display: flex;
            width: 100%;
            padding: 10px 15px;
            align-items: center;
            font-weight: bold;

            span{
                margin-left: 5px;
            }
        }

        .center {
            font-weight: 500;

            ul {
                margin-top: 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                list-style: none;
                padding: 0;

                li {
                    margin-bottom: 2px;
                    background-color: #92ada23b;
                    padding: 10px 15px;
                    width: 100%;
                    border-bottom: 0.5px solid #b2cfbf16;
                    cursor: pointer;

                    &:hover{
                        color: white;
                    }
                }
            }
        }

        .bottom {
            flex: 1;
            padding: 25px 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            
            div{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid white;
                padding: 5px;
                font-weight: bold;
                color: white;
                cursor: pointer;

                &:hover{
                    background-color: white;
                    color: #004D22;
                }

                span{
                    margin-left: 5px;
                }
            }
        }

    }
}