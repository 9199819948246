.singleitem {
    display: flex;

    .singleItemContainer {
        flex: 18;
        background-color: #47aa7721;
        display: flex;
        justify-content: center;

        .singleItemContainerContent {
            flex: 1;
            max-width: 800px;
            display: flex;
            flex-direction: column;

            .top {
                padding: 30px 30px;

                h3{
                    color: #004D22;
                }
            }

            .center {
                flex: 1;
                background-color: #FFF;
                -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);
                box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);

                .field-input {
                    //background-color: aqua;
                    display: flex;
                    padding: 5px 30px 0px 30px;
                    gap: 10px;

                    .field-input__header {
                        flex: 2;
                        padding: 3px 10px;
                        display: flex;
                        align-items: center;

                        label {
                            font-weight: bold;
                            font-size: 13px;
                            color: #004D22;
                        }

                        
                    }

                    .field-input__wrapper {
                        flex: 7;
                        padding: 3px 10px;

                        .field-input__container {

                            .field-input__input {
                                width: 100%;

                                input{
                                    height: 15px;
                                }
                            }
                        }

                    }
                }

                .divider-line{
                    padding: 20px 40px 10px;

                    hr{
                        color: #47aa7721;
                    }
                }

            }
        }
    }
}