.singleMessage{
    min-width: 80px;
    max-width: 500px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    
    .msgText{
        display: flex;
    }
    
    .msgTime{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: end;
        font-size: 12px;
        color: grey;
    }
}

.sentByUser{
    align-self: flex-start;
}

.sentByBot{
    background-color: #D9FDD3;
}