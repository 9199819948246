.profileContainer {
    display: flex;
    flex-direction: column;

    .top {
        display: flex;
        height: 150px;
        flex-direction: column;

        .title {
            padding-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            //background-color: #47aa7710;
            background-color: #1E96C8;
            color: #FFFFFF;
            height: 30px;
        }

        .content {
            display: flex;

            .avatarContainer {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .userData {
                flex: 3;
                padding: 20px 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .phoneNumber,
                .telegramId {
                    display: flex;
                }
            }
        }
    }

    .bottom {
        flex: 1;
        display: flex;
        flex-direction: column;

        .title{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #47AA77;
            color: #FFFFFF;
            height: 30px;
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            
            .verifiedUser {
                padding: 5px 10px;

                p {
                    padding: 0 0 5px 5px;
                    font-weight: bold;
                }
            }

            .associatedUser {
                flex: 1;
                padding: 5px 10px;
                margin-bottom: 50px;

                &.isVerified {
                    background-color: #47aa7710;
                }

                &.notVerified {
                    background-color: rgba(253, 114, 114, 0.476);

                }

                .personalDetails,
                .companyDetails{
                    padding: 10px;

                    p{
                        &.subtitle{
                            font-weight: bold;
                            font-size: 13px;
                        }
                    }

                }

                .verifiedStatus{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0;
                    gap: 10px;

                    .iconContainer{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .statusText{
                        display: flex;
                        flex-direction: column;
                        margin-right: 15px;
                        p{
                            font-weight: bold;
                            font-size: 12px;
                        }
                    }
                }

            }
        }

    }

}