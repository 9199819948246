.resultsContainer{
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .resultsHeader{
        padding: 5px;
    }

    .resultsContent{
        flex: 1;
        padding: 5px;
    }
    

}