.conversationMessagesWrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;



    .conversationMessages {
        overflow: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 20px 100px;
        align-items: flex-end;

        &::-webkit-scrollbar{
            width: 5px;
        }
    
        &::-webkit-scrollbar-thumb{
            background-color: #004d216b;
        }

        .userMessage,
        .botMessage {
            background-color: #FFFFFF;
            padding: 5px 10px;
            border-radius: 10px;
        }


        .userMessage {
            align-self: flex-start;
        }

        .botMessage {
            background-color: #D9FDD3;
        }

    }

    .conversationActions {
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;

        .conversationTabs {
            display: flex;
            align-items: center;
            gap: 20px;
            height: 50px;
            border-bottom: 0.5px solid lightgray;
        }
    }
}