.navbar{
    display: flex;
    align-items: center;
    height: 55px;

    .titleCounter{
        display: flex;
        align-items: center;
        width: 200px;
        padding: 15px 10px;
        color: #004D22;

        span{
            padding-left: 5px;
            font-weight: bold;
        }
    }

    .searchbox{
        width: 300px;
    }

    .buttonGroup{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
    }
}