.singleIntegrationForm {
    flex: 18;
    background-color: #47aa7721;
    display: flex;
    justify-content: center;

    .singleIntegrationFormContainer {
        flex: 1;
        max-width: 80%;
        display: flex;
        flex-direction: column;

        .header {
            padding: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                color: #004D22;
            }
        }

        .content {
            flex: 1;
            background-color: #FFF;
            -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);
            box-shadow: 0px 3px 4px 0px rgba(0, 122, 75, 0.11);

            .field-input {
                display: flex;
                padding: 5px 30px 0 30px;
                gap: 10px;

                .field-input__header {
                    flex: 2;
                    padding: 3px 10px;
                    display: flex;
                    align-items: center;

                    label {
                        font-weight: bold;
                        font-size: 13px;
                        color: #004D22;
                    }
                }

                .field-input__wrapper {
                    flex: 7;
                    padding: 3px 10px;

                    .field-input__container {

                        .field-input__input {
                            width: 100%;

                            input {
                                height: 15px;
                            }
                        }

                        .refresh-schedule {
                            //background-color: red;
                            padding: 5px 0;
                            display: flex;
                            gap: 10px;
                            padding-right: 40px;

                            .weekdays {
                                flex: 1;
                                //background-color: cyan;

                                .weekdaysToggleButtonGroup {
                                    margin-top: 5px;
                                    display: flex;
                                    gap: 1px;

                                    .weekdayToggleButton {
                                        background-color: #FFF;
                                        color: #004D22;

                                        &.Mui-selected {
                                            background-color: #004D22;
                                            color: #FFF;
                                        }
                                    }
                                }
                            }

                            .frequency {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                .container {
                                    margin-top: 5px;
                                    display: flex;
                                    gap: 20px;

                                    .quantity {
                                        flex: 1;
                                    }

                                    .unit {
                                        flex: 2;
                                    }
                                }
                            }

                            .timeslot {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                .container {
                                    margin-top: 5px;
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;

                                    .fromTime,
                                    .untilTime {
                                        display: flex;
                                        gap: 2px;
                                        align-items: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                gap: 20px;
                padding: 30px 40px;

                .submitBtn,
                .cancelBtn {
                    display: flex;
                    justify-content: flex-end;
                    //padding: 30px 40px;
                }
            }
        }


    }
}